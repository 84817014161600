@import "../mixins/buttons";
/**  =====================
      Button css start
==========================  **/
.btn {
  padding: 10px 20px;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;

  > i {
    margin-right: 12px;
  }

  // &:active,
  // &:focus {
  //     box-shadow: none;
  // }

  &-square {
    border-radius: 0;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.55;
  }

  &-rounded {
    border-radius: 30px;
  }

  .spinner-border {
    // border-width: 0.2em;
    // border-right-width: 0.2em;
    // border-bottom-width: 0;
    // border-left-width: 0;
  }

  .spinner-border {
    margin-right: 5px;
  }
}

.dropdown-toggle::after {
  top: 3px;
  left: 8px;
  position: relative;
}

.dropdown-toggle-split {
  padding: 8px 10px;

  &:after {
    left: 0;
  }
}

.btn-icon,
.drp-icon {
  width: 40px;
  height: 40px;
  padding: 10px 12px;

  > i {
    margin-right: 0;
  }
}

.drp-icon {
  &.dropdown-toggle:after {
    display: none;
  }

  + .dropdown-menu {
    margin-left: -10px;
  }
}

.btn-lg {
  padding: 12px 25px;
  border-radius: 0.35rem;
}

.btn-sm {
  padding: 3px 12px;
  border-radius: 0.15rem;
}

.btn-group,
.btn-group-vertical {
  .btn {
    margin-bottom: 0;
    margin-right: 0;
  }
}
.shadow-1 {
  box-shadow: 0 7px 12px 0 rgba(62, 57, 107, 0.16);
}

.shadow-2 {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);
}

.shadow-3 {
  box-shadow: 0 14px 24px 0 rgba(62, 57, 107, 0.26);
}

.shadow-4 {
  box-shadow: 0 16px 28px 0 rgba(62, 57, 107, 0.3);
}

.shadow-5 {
  box-shadow: 0 20px 24px 0 rgba(62, 57, 107, 0.36);
}
/* Alternate buttons */
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}
/* outline buttons */
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
/* glow buttons */
@each $color, $value in $theme-colors {
  .btn-glow-#{$color} {
    @include button-glow-variant($value);
  }
}
/* Gradient */
@each $color, $value in $theme-colors {
  .btn-gradient-#{$color} {
    @include button-gradient-variant($value, $value);
  }
}
/**====== Button css end ======**/
/*====== Social button css starts ======*/
@each $value in $social-name {
  $i: index($social-name, $value);

  .btn-#{$value} {
    background: nth($social-color, $i);
    border-color: nth($social-color, $i);
    color: #fff;

    &:hover,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    .show > &.dropdown-toggle {
      color: #fff;
    }
  }
}
/*====== Social button css end ======*/
