@import "../variables"; /**  =====================
      timeline css start
==========================  **/
// horizontal-timeline

.cd-horizontal-timeline {
  opacity: 0;
  transition: opacity 0.2s;

  &.loaded {
    opacity: 1;
  }

  .timeline {
    position: relative;
    height: 100px;
  }

  .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
  }

  .events {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    background: $theme-border;
    transition: all 0.4s;
  }

  .filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $primary-color;
    transform: scaleX(0);
    transform-origin: left center;
    transition: all 0.3s;
  }

  .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    font-size: 1rem;
    padding-bottom: 15px;
    color: $theme-font-color;
    transform: translateZ(0);

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      bottom: -5px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 2px solid $theme-border;
      background-color: $theme-background;
      transition: background-color 0.3s, border-color 0.3s;
    }

    .no-touch &:hover::after {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &.selected {
      pointer-events: none;
      font-weight: 600;

      &::after {
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }

    &.older-event::after {
      border-color: $primary-color;
    }
  }
}

.cd-timeline-navigation a {
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid $theme-border;
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  transition: border-color 0.3s;

  &::after {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    background: url("/assets/images/cd-arrow.svg") no-repeat 0 0;
  }

  &.prev {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
  }

  &.next {
    right: 0;
  }

  .no-touch &:hover {
    border-color: $primary-color;
  }

  &.inactive {
    cursor: not-allowed;

    &::after {
      background-position: 0 -16px;
    }

    .no-touch &:hover {
      border-color: $theme-border;
    }
  }
}

.cd-horizontal-timeline .events-content {
  position: relative;
  overflow: hidden;
  transition: height 0.4s;

  li {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    opacity: 0;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;

    em::before {
      content: "- ";
    }

    &.selected {
      position: relative;
      z-index: 2;
      opacity: 1;
      transform: translateX(0);
    }

    &.enter-right,
    &.leave-right {
      animation-name: cd-enter-right;
    }

    &.enter-left,
    &.leave-left {
      animation-name: cd-enter-left;
    }

    &.leave-left,
    &.leave-right {
      animation-direction: reverse;
    }
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
//==================================
// Vertical-timeline
@media only screen and (min-width: 1170px) {
  .cd-is-hidden {
    visibility: hidden;
  }
}

.cd-timeline {
  overflow: hidden;
  margin: 2em auto;
  background: $theme-background;
}

.cd-timeline__container {
  position: relative;
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 2em 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: $theme-border;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .cd-timeline__container::before {
    left: 50%;
    margin-left: -2px;
  }
}

.cd-timeline__block {
  position: relative;
  margin: 2em 0;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline__block {
    margin: 4em 0;
  }
}

.cd-timeline__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);

  i,
  img {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
  }

  i {
    font-size: 24px;
    color: #fff;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline__img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    transform: translateZ(0);

    &.cd-timeline__img--bounce-in {
      visibility: visible;
      animation: cd-bounce-1 0.6s;
    }
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.cd-timeline__content {
  position: relative;
  margin-left: 60px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &::before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-right: 10px solid #fff;
    filter: drop-shadow(1px 0 1px $theme-border);
  }
}

.cd-timeline__date {
  font-size: 1rem;
  display: inline-block;
  float: left;
  padding: 0.4em 0;
  opacity: 1;
  color: $theme-heading-color;
}
@media only screen and (min-width: 768px) {
  .cd-timeline__date {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline__content {
    margin-left: 0;
    width: 45%;
    transform: translateZ(0);

    &::before {
      top: 18px;
      left: 100%;
      border-color: transparent;
      border-left-color: #fff;
    }
  }

  .cd-timeline__date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 1.4rem;
  }

  .cd-timeline__block:nth-child(even) {
    .cd-timeline__content {
      float: right;

      &::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: #fff;
        filter: drop-shadow(-1px 0 1px $theme-border);
      }
    }

    .cd-timeline__date {
      left: auto;
      right: 122%;
      text-align: right;
    }
  }

  .cd-timeline__content.cd-timeline__content--bounce-in {
    visibility: visible;
    animation: cd-bounce-2 0.6s;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline__block:nth-child(even)
    .cd-timeline__content.cd-timeline__content--bounce-in {
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
  }
}
@media only screen and (max-width: 575px) {
  .cd-timeline__content {
    margin-left: 50px;
    padding: 1rem 0;

    &:before {
      display: none;
    }
  }

  .cd-timeline__date {
    display: block;
    width: 100%;
  }
}
/**====== timeline css end ======**/
