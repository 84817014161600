// =======================================
//     List of variables for layout
// =======================================
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
$header-height: 70px;
$Menu-width: 264px;
$Menu-collapsed-width: 70px;

// color list for build layouts
$primary-color: #2196f3;
$warning-color: #ff9800;
$danger-color: #f44336;
$success-color: #4caf50;
$purple-color: #7759de;
$info-color: #00acc1;
$secondary-color: #748892;
$default-color: #d6d6d6;
$light-color: #eceff1;
$dark-color: #263238;
$inverse-color: $dark-color;
$theme-border: #e2e5e8;

// header
$header-dark-background: #242e3e;
$header-dark-text-color: #fff;
$header-light-background: #fff;
$header-light-text-color: #242e3e;

// Menu
$menu-dark-background: #242e3e;
$menu-dark-text-color: #b5bdca;
$menu-light-background: #fff;
$menu-light-text-color: #242e3e;
$menu-active-color: $primary-color;

// dark layout colors
$dark-layout: darken(#242e3e, 8%);
$dark-layout-font: #adb7be;

// Menu icon
$menu-icon-color: #2196f3, #f44336, #01a9ac, #7759de, #00acc1, $warning-color;
// Header background
$color-header-name: blue, red, purple, info, dark;
$color-header-color: #2196f3, #f44336, #7759de, #00acc1, #242e3e;
// Menu background
$color-menu-name: blue, red, purple, info, dark;
$color-menu-color: #2196f3, #f44336, #7759de, #00acc1, #242e3e;

// Menu background gradient
$color-menu-gradient-name: blue, red, purple, info, dark;
$color-menu-gradient-color: linear-gradient(to bottom, #7759de 0%, #2196f3 100%),
  linear-gradient(
    to bottom,
    rgb(164, 69, 178) 0%,
    rgb(212, 24, 114) 52%,
    rgb(255, 0, 102) 100%
  ),
  linear-gradient(to top, rgb(178, 117, 240) 0%, rgb(117, 117, 240) 100%),
  linear-gradient(to bottom, rgb(1, 169, 172) 0%, rgb(1, 219, 223) 100%),
  linear-gradient(to bottom, rgb(36, 46, 62) 0%, rgb(58, 76, 103) 100%);

// Active background color
$color-active-name: blue, red, purple, info, dark;
$color-active-color: #2196f3, #f44336, #7759de, #00acc1, $dark-color;
// Menu title color
$color-title-name: blue, red, purple, info, dark;
$color-title-color: #2196f3, #f44336, #7759de, #00acc1, $dark-color;

$theme-font-family: "Roboto", sans-serif;
$theme-font-size: 12px;
$theme-background: #eff3f6;
$theme-font-color: #686c71;
$theme-heading-color: #111;

$color-name: c-blue, c-red, c-green, c-yellow, c-purple;
$color-color: $primary-color, $danger-color, $success-color, $warning-color,
  $purple-color;
$color-bt-name: primary, danger, success, warning, info, purple;
$color-bt-color: $primary-color, $danger-color, $success-color, $warning-color,
  $info-color, $purple-color;

// form-control variables
$form-bg: #f0f3f6;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "success": $success-color,
    "info": $info-color,
    "warning": $warning-color,
    "danger": $danger-color,
    "light": $light-color,
    "dark": $dark-color,
    "purple": $purple-color
  ),
  $theme-colors
);
// Color contrast
$yiq-contrasted-threshold: 200 !default;
$yiq-dark-color: #37474f;
$yiq-light-color: #fff;
$theme-color-interval: 8% !default;
$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

// social color variables
$social-name: facebook, twitter, dribbble, pinterest, youtube, googleplus,
  instagram, viber, behance, dropbox, linkedin;
$social-color: #3c5a99, #42c0fb, #ec4a89, #bf2131, #e0291d, #c73e2e, #aa7c62,
  #7b519d, #0057ff, #3380ff, #0077b5;
