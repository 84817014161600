/**  =====================
      Authentication css start
==========================  **/

.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  .saprator {
    position: relative;
    margin: 8px 0;

    span {
      background: #fff;
      position: relative;
      padding: 0 10px;
      z-index: 5;
      font-size: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 0;
      width: 100%;
      height: 1px;
      background: $theme-border;
      z-index: 1;
    }
  }

  a,
  p > a {
    color: $theme-heading-color;
    font-weight: 600;
  }

  .btn-auth-gen {
    .btn-icon {
      width: 140px;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 45px;

      small {
        font-size: 15px;
      }
    }
  }

  .input-group {
    background: transparent;
  }

  .card {
    margin-bottom: 0;
    padding: 8px;

    .card-body {
      padding: 20px 25px 20px 40px;
    }
  }

  > div {
    z-index: 5;
  }

  .auth-content {
    position: relative;
    padding: 15px;
    z-index: 5;
  }
  @media only screen and (max-width: 767px) {
    .card {
      .card-body {
        padding: 30px 15px;
      }
    }
  }

  &.offline {
    background-image: none;

    &:before {
      display: none;
    }
  }

  &.error {
    background: $danger-color;

    .container {
      position: relative;
      max-width: 800px;
      margin: 0 auto 5%;
      padding: 5%;
      text-align: center;
      color: #fff;
    }
  }

  .card-body .carousel-indicators {
    margin: 30px 0;
    bottom: 0;

    li {
      width: 50px;
      background-color: $theme-background;

      &.active {
        background-color: $primary-color;
      }
    }
  }

  .img-logo-overlay {
    position: absolute;
    top: 40px;
    left: 50px;
  }
}
/* image varient start */

.aut-bg-img {
  background-image: url("/assets/images/auth-bg-big.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.aut-bg-img-side {
  p {
    line-height: 2;
  }
}

.aut-bg-img,
.aut-bg-img-side {
  .custom-select,
  .form-control {
    background: transparent;
  }

  .auth-content {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

.blur-bg-images {
  background: linear-gradient(to bottom, #000428, #004e92);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
}

.auth-prod-slider {
  .blur-bg-images:after {
    background: $menu-dark-background;
  }

  .auth-prod-slidebg {
    height: 600px;
    width: 100%;

    &.bg-1 {
      background-image: linear-gradient(
        to right,
        transparentize($purple-color, 0.1) 0%,
        transparentize($primary-color, 0.1) 100%
      );
    }

    &.bg-2 {
      background-image: linear-gradient(
        to right,
        transparentize($primary-color, 0.1) 0%,
        transparentize($info-color, 0.1) 100%
      );
    }

    &.bg-3 {
      background-image: linear-gradient(
        to right,
        transparentize($info-color, 0.1) 0%,
        transparentize($primary-color, 0.1) 100%
      );
    }

    &.bg-4 {
      background-image: linear-gradient(
        to right,
        transparentize($primary-color, 0.1) 0%,
        transparentize($purple-color, 0.1) 100%
      );
    }
  }

  .carousel-indicators {
    li {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      transition: all 0.35s ease-in-out;
      border-top: none;
      border-bottom: none;

      &.active {
        width: 20px;
        background: $primary-color;
      }
    }
  }

  .carousel-caption {
    bottom: auto;
    top: 50px;

    img {
      border-radius: 5px;
      border: 4px solid #fff;
    }

    h5 {
      color: #fff;
    }
  }
}
/* image varient End */
/**====== Authentication css end ======**/
