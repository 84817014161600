.demo-1 {
  .main-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-image: url("/assets/images/search/bg.svg");
    background-repeat: no-repeat;
    background-position: 50% 10em;
    background-size: 75% auto;
  }
}
.demo-2 {
  .main-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-image: url("/assets/images/search/bg.svg");
    background-repeat: no-repeat;
    background-position: 50% 10em;
    background-size: 75% auto;
    margin-bottom: 105px;
  }
}
.demo-3 {
  .main-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-image: url("/assets/images/search/bg.svg");
    background-repeat: no-repeat;
    background-position: 50% 10em;
    background-size: 75% auto;
  }
}
.btn--search {
  font-size: 1.5em;
  &.search3 {
    color: #fff !important;
  }
}

.search__input {
  border: 0;
  background: transparent;
  border-radius: 0;
  -webkit-appearance: none;
}

.search__input:focus {
  outline: none;
}

.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}

.btn--search .icon,
.btn--search-close .icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
  margin: 0 auto;
  fill: currentColor;
}

.codrops-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 2.5em 2.75em 2.5em 1.5em;
  text-align: left;
}

.codrops-header__side,
.search-wrap {
  margin: 0 0 0 auto;
}

.bottom-nav {
  position: relative;
  width: 100%;
  margin: auto 0 0 0;
  padding: 2.5em 2.75em 2.5em 2em;
}

/* Demo links */
.codrops-demos {
  font-weight: bold;
  padding: 1.25em 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.codrops-demos span {
  display: inline-block;
  margin: 0 1em 0 0;
  vertical-align: bottom;
}

.codrops-demos a {
  position: relative;
  display: inline-block;
  margin: 0 0.5em;
  padding: 0 0.4em;
}

.codrops-demos a.current-demo::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.25em;
  height: 2.25em;
  margin: -1.125em 0 0 -1.125em;
  border: 2px solid;
  border-radius: 50%;
}

/* Demo colors */
.demo-2 {
  color: #2196f3;
}

/* .demo-2 main { background-color: #2c312e; } */
.demo-2 .main-wrap a,
.demo-2 .main-wrap .btn {
  color: #2196f3;
}

.demo-2 .main-wrap .codrops-header {
  margin-top: auto;
}

.demo-3 {
  background-color: #2f2c2c;
}

.demo-3 main {
  background-image: url("/assets/images/search/bg_2.svg");
}

.demo-3 .main-wrap a,
.demo-3 .main-wrap.btn {
}

.demo-3 .main-wrap .btn--search-close {
  color: #f44336;
}

@media screen and (max-width: 40em) {
  .codrops-header {
    display: block;
    padding: 4em 1em 0;
    text-align: center;
  }

  .codrops-header__side {
    margin: 0 auto;
  }

  .codrops-links {
    font-size: 0.85em;
    margin: 0 auto;
  }

  .search-wrap {
    font-size: 0.85em;
    position: absolute;
    top: 2.15em;
    right: 2em;
  }

  .bottom-nav {
    padding: 0.5em;
  }

  .codrops-demos {
    text-align: center;
  }

  .codrops-demos a {
    margin-bottom: 1em;
  }

  .codrops-demos span {
    display: block;
    margin: 0 auto 1em;
    text-align: center;
  }
}
.js1 {
  /* Layout for search container */
  .search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, 1);
  }

  .search {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
  }

  .search::before,
  .search::after {
    content: "";
    position: absolute;
    width: calc(100% + 15px);
    height: calc(100% + 15px);
    pointer-events: none;
    border: 1.5em solid #2196f3;
  }

  .search::before {
    top: 0;
    left: 0;
    border-right-width: 0;
    border-bottom-width: 0;
  }

  .search::after {
    right: 0;
    bottom: 0;
    border-top-width: 0;
    border-left-width: 0;
  }

  .btn--search-close {
    font-size: 1.1em;
    position: absolute;
    top: 1.25em;
    right: 1.25em;
    display: none;
  }

  .btn--search-close {
    display: block;
  }

  .search__form {
    margin: 5em 0;
  }

  .search__input {
    font-family: inherit;
    font-size: 10vw;
    line-height: 1;
    display: inline-block;
    box-sizing: border-box;
    width: 75%;
    padding: 0.05em 0;
    border-bottom: 5px solid;
    color: #2196f3;
  }

  .search__input::-webkit-search-cancel-button,
  .search__input::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .search__input::-ms-clear {
    display: none;
  }

  .search__info {
    font-size: 90%;
    font-weight: bold;
    display: block;
    width: 75%;
    margin: 0 auto;
    padding: 0.85em 0;
    text-align: right;
    /* color: #d17c78; */
  }

  .search__related {
    display: flex;
    width: 75%;
    pointer-events: none;
  }

  .search__suggestion {
    width: 33.33%;
    text-align: left;
  }

  .search__suggestion:nth-child(2) {
    margin: 0 3em;
  }

  .search__suggestion h3 {
    font-size: 1.35em;
    margin: 0;
  }

  .search__suggestion h3::before {
    content: "\21FE";
    display: inline-block;
    padding: 0 0.5em 0 0;
  }

  .search__suggestion p {
    font-size: 1.15em;
    line-height: 1.4;
    margin: 0.75em 0 0 0;
  }

  /************************/
  /* Transitions 			*/
  /************************/

  .search {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .search--open {
    pointer-events: auto;
    opacity: 1;
  }

  /* Border */
  .search::before,
  .search::after {
    transition: transform 0.5s;
  }

  .search::before {
    transform: translate3d(-15px, -15px, 0);
  }

  .search::after {
    transform: translate3d(15px, 15px, 0);
  }

  .search--open::before,
  .search--open::after {
    transform: translate3d(0, 0, 0);
  }

  /* Close button */
  .btn--search-close {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1);
    transition: opacity 0.5s, transform 0.5s;
  }

  .search--open .btn--search-close {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  /* Search form with input and description */
  .search__form {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1);
    transition: opacity 0.5s, transform 0.5s;
  }

  .search--open .search__form {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  .search__suggestion {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
    transition: opacity 0.5s, transform 0.5s;
  }

  .search--open .search__suggestion {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .search--open .search__suggestion:nth-child(2) {
    transition-delay: 0.1s;
  }

  .search--open .search__suggestion:nth-child(3) {
    transition-delay: 0.2s;
  }

  @media screen and (max-width: 40em) {
    .search__form {
      margin: 5em 0 1em;
    }
    .btn--search-close {
      font-size: 1.25em;
      top: 1.5em;
      right: 1.5em;
    }
    .search__info {
      text-align: left;
    }
    .search__suggestion {
      font-size: 80%;
      width: 100%;
    }
    .search__suggestion:nth-child(2),
    .search__suggestion:nth-child(3) {
      display: none;
    }
  }
}
.js2 {
  .search {
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* makes other page elements clickable */
  }

  .search::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.8);
  }

  .btn--search-close {
    font-size: 2em;
    position: absolute;
    top: 1.25em;
    right: 1.25em;
    display: none;
  }

  .btn--search-close {
    display: block;
  }

  .search__form {
    position: relative;
    width: 50%;
    min-width: 300px;
    margin: 0 auto;
  }

  .search__input {
    font-family: inherit;
    font-size: 5em;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1em 0.15em 0;
    pointer-events: auto;
    color: #2196f3;
    border-bottom: 4px solid;
  }

  .search__input::-webkit-search-cancel-button,
  .search__input::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .search__input::-ms-clear {
    display: none;
  }

  .btn--search {
    font-size: 3em;
    position: absolute;
    right: 0;
    bottom: -10px;
    pointer-events: none;
    color: #e8db82;
  }

  .search--open .btn--search {
    pointer-events: auto;
  }

  .search__suggestion {
    width: 50%;
    max-width: 450px;
    margin: calc(40vh + 2em) auto 0;
    text-align: left;
    pointer-events: none;
  }

  .search__suggestion:nth-child(2) {
    margin: 0 3em;
  }

  .search__suggestion h3 {
    font-size: 1.5em;
    margin: 0;
    color: #fff;
  }

  .search__suggestion h3::before {
    content: "\21FE";
    display: inline-block;
    padding: 0 0.5em 0 0;
  }

  .search__suggestion p {
    font-size: 1.25em;
    line-height: 1.4;
    margin: 0.75em 0 0 0;
    color: #bbb;
  }

  /************************/
  /* Transitions 			*/
  /************************/
  .search::before {
    opacity: 0;
    transition: opacity 0.5s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }

  .search--open::before {
    opacity: 1;
  }

  .search--open {
    pointer-events: auto;
  }

  .search__form {
    transform: scale3d(0.5, 0.5, 1);
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }

  .search--open .search__form {
    transform: translate3d(0, 40vh, 0) translate3d(0, -50%, 0);
  }

  .btn--search-close {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1);
    transition: opacity 0.5s, transform 0.5s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }

  .search--open .btn--search-close {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition-delay: 0.1s;
  }

  /* Dummy blocks */
  .search__suggestion h3,
  .search__suggestion p {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    transition: opacity 0.2s, transform 0.5s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }

  .search--open .search__suggestion h3,
  .search--open .search__suggestion p {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s, transform 0.5s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }

  .search--open .search__suggestion p {
    transition-delay: 0.1s;
  }

  @media screen and (max-width: 40em) {
    .search__form {
      width: 100%;
      padding: 2em 1em 0;
    }
    .search__input {
      font-size: 2em;
    }
    .btn--search {
      font-size: 1.25em;
      right: 1em;
    }
    .btn--search-close {
      font-size: 1.15em;
    }
    .search__suggestion {
      font-size: 0.85em;
      width: 100%;
      margin-top: 40vh;
      padding: 0 1em;
    }
  }
}
.js3 {
  /* Layout for search container */
  .search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .search {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
  }

  .search::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }

  .btn--search-close {
    font-size: 2em;
    position: absolute;
    top: 1.25em;
    right: 1.25em;
    display: none;
  }

  .btn--search-close {
    display: block;
  }

  .search__form {
    margin: 5em 0;
  }

  .search__input {
    font-family: inherit;
    font-size: 10vw;
    line-height: 1;
    display: inline-block;
    box-sizing: border-box;
    width: 75%;
    padding: 0.05em 0;
    color: #2196f3;
    border-bottom: 5px solid;
  }

  .search__input::-webkit-search-cancel-button,
  .search__input::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  .search__input::-ms-clear {
    display: none;
  }

  .search__info {
    font-size: 90%;
    font-weight: bold;
    display: block;
    width: 75%;
    margin: 0 auto;
    color: #bbb;
    padding: 0.85em 0;
    text-align: right;
  }

  .search__related {
    display: flex;
    width: 75%;
    text-align: left;
    pointer-events: none;
  }

  .search__suggestion {
    width: 50%;
  }

  .search__suggestion:first-child {
    padding: 0 2em 0 0;
  }

  .search__suggestion:last-child {
    padding: 0 0 0 2em;
  }

  .search__suggestion h3 {
    font-size: 1.35em;
    margin: 0;
    color: #fff;
  }

  .search__suggestion h3::before {
    content: "\21FE";
    display: inline-block;
    padding: 0 0.5em 0 0;
  }

  .search__suggestion p {
    font-size: 1.15em;
    line-height: 1.4;
    margin: 0.75em 0 0 0;
    color: #bbb;
  }

  /************************/
  /* Transitions 			*/
  /************************/

  .main-wrap {
    position: relative;
    transition: transform 0.5s;
  }

  .main-wrap--move {
    transform: scale3d(0.7, 0.7, 1);
  }

  .main-wrap--move .btn--search {
    pointer-events: none;
    opacity: 0;
  }

  .search {
    pointer-events: none;
  }

  .search--open {
    pointer-events: auto;
  }

  .search::before {
    opacity: 0;
    transition: opacity 0.5s;
  }

  .search--open::before {
    opacity: 1;
  }

  /* Close button */
  .btn--search-close {
    opacity: 0;
    transition: opacity 0.5s;
    color: #fff;
  }

  .search--open .btn--search-close {
    opacity: 1;
  }

  /* Search form with input and description */

  .search__suggestion,
  .search__info {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
    transition: opacity 0.4s, transform 0.4s;
  }

  .search--open .search__suggestion,
  .search--open .search__info {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .search--open .search__suggestion:first-child {
    transition-delay: 0.15s;
  }

  .search--open .search__suggestion:nth-child(2) {
    transition-delay: 0.2s;
  }

  .search__input {
    transform: scale3d(0, 1, 1);
    transform-origin: 0% 50%;
    transition: transform 0.3s;
  }

  .search--open .search__input {
    transform: scale3d(1, 1, 1);
    transition-duration: 0.5s;
  }

  @media screen and (max-width: 40em) {
    .btn--search-close {
      font-size: 1.25em;
    }
    .search__suggestion {
      font-size: 80%;
      width: 100%;
    }
    .search__suggestion:nth-child(2),
    .search__suggestion:nth-child(3) {
      display: none;
    }
  }
}
